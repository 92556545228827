



















import Base from '../../application/models/Base'
import { Component } from 'vue-property-decorator'
import { Threshold } from '@/application/models/Thresholds'
import Hospital from '@/application/models/Hospital'
import { Getter } from 'vuex-class'

@Component({})
export default class ThresholdView extends Base {
  @Getter('thresholds', { namespace: 'thresholds' })
  thresholds!: Array<Threshold>

  constructor () {
    super('threshold-view')
  }
}
